import React, { useEffect, useState } from "react";

import SliderContent from "../auxiliary/SliderContent";
import Dots from "../auxiliary/Dots";
import Arrows from "../auxiliary/Arrows";
import sliderImage from "../auxiliary/sliderImage";

import "../../Assets/css/membership.css";
import "../../Assets/css/slider.css";

const len = sliderImage.length - 1;

function Membership() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div>
      <div className="slider-container">
        <SliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
        <Arrows
          prevSlide={() =>
            setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
          }
          nextSlide={() =>
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
          }
        />
        <Dots
          activeIndex={activeIndex}
          sliderImage={sliderImage}
          onclick={(activeIndex) => setActiveIndex(activeIndex)}
        />
      </div>
      <div className="membership-tiers-title">Membership Tiers</div>
      <div className="membership-tiers-cont">
      <a className="patreon-starter-link" href="https://www.patreon.com/thesavegpodcast" target="_blank" rel="noreferrer">
        <div className="saveg-card">
          <div className="saveg-starter-title">The SaVeg Starter</div>
          <div className="saveg-starter-price">$4.50CA/month</div>
          <div className="saveg-starter-benefits-title">
            Benefits of the SaVeg Starter include:
          </div>
          <div className="saveg-starter-benefits-cont">
          <ul className="saveg-starter-benefits">
            <li>Access to every podcast early (without ads)</li>
            <li>Exclusive content</li>
            <li>Video version of the Podcasts (without ads)</li>
            <li>One BONUS patreon exclusive podcast per month</li>
            <li>Our love and appreciation</li>
          </ul>
          </div>
        </div>
        </a>
        <a className="patreon-gang-link" href="https://www.patreon.com/thesavegpodcast" target="_blank" rel="noreferrer">
        <div className="saveg-card">
          <div className="saveg-gang-title">Join the SaVeg Gang</div>
          <div className="saveg-gang-price">$7.50CA/month</div>
          <div className="saveg-gang-benefits-title">
            Benefits for the SaVeg Gang members include all of those mentioned in the previous tier plus:
          </div>
          <ul className="saveg-gang-benefits">
            <li>Ask Questions for us to answer in our BONUS Patreon only episodes</li>
            <li>Our love and appreciation</li>
          </ul>
        </div>
        </a>
        <a className="patreon-epic-link" href="https://www.patreon.com/thesavegpodcast" target="_blank" rel="noreferrer">
        <div className="saveg-card">
          <div className="saveg-epic-title">Epic SaVeg Gang</div>
          <div className="saveg-epic-price">$14.50CA/month</div>
          <div className="saveg-epic-benefits-title">
            Benefits for Epic SaVeg Gang members include all those mentioned in the previous tier plus:
          </div>
          <ul className="saveg-epic-benefits">
            <li>Priority in suggesting topics for future episodes</li>
            <li>Of course our continued love and appreciation</li>
          </ul>
        </div>
        </a>
        <div className="saveg-card-fill">
            <img className="saveg-fruit-vegetables" src="saveg_fruit_vegetables.svg" alt="Fruit and Vegetables"></img>
        </div>
      </div>
    </div>
  );
}

export default Membership;
