import React from "react";

import "../../Assets/css/footer.css";

function Footer() {
  return (
    <footer>
      <div className="footerContainer">
      <a className="patreon-link" href="https://www.patreon.com/thesavegpodcast" target="_blank" rel="noreferrer"><img className="patreon-logo" src="patreon-ar21.svg"></img></a>
      <a className="youtube-link" href="https://www.youtube.com/c/TheSaVegPodcast" target="_blank" rel="noreferrer"><img className="youtube-logo" src="youtube_logo_2017.svg"></img></a>
      <a className="spotify-link" href="https://open.spotify.com/show/7g4EWKA0tkI6GX9H0KeA09" target="_blank" rel="noreferrer"><img className="spotify-logo" src="spotify-1.svg"></img></a>
      <a className="soundcloud-link" href="https://soundcloud.com/thesavegpodcast" target="_blank" rel="noreferrer"><img className="soundcloud-logo" src="Soundcloud_logo.svg"></img></a>
      <a className="apple-link" href="https://podcasts.apple.com/ca/podcast/the-saveg-podcast/id1490026125" target="_blank" rel="noreferrer"><img className="apple-logo" src="Apple_logo_black.svg"></img></a>
      <a className="instagram-link" href="https://www.instagram.com/thesavegpodcast/" target="_blank" rel="noreferrer"><img className="instagram-logo" src="Instagram_logo_2016.svg"></img></a>
      <a className="facebook-link" href="https://www.facebook.com/thesavegpodcast/" target="_blank" rel="noreferrer"><img className="facebook-logo" src="Facebook_f_logo_(2021).svg"></img></a>
      <a className="tiktok-link" href="https://www.tiktok.com/@thesavegpodcast" target="_blank" rel="noreferrer"><img className="tiktok-logo" src="tiktok.svg"></img></a>
      <img className="saveg-logo-footer" src="saveg_logo_colored.svg"></img>
      <div className="footer-email">thesavegpodcast@gmail.com</div>
        <div className="copyright">
          Copyright <span>&#169;</span> 2024 The SaVeg Podcast &nbsp;&nbsp; | &nbsp;&nbsp; Powered by <a className="web-design-link" href="https://www.simplewebsitedesign.ca" target="_blank" rel="noreferrer">simplewebsitedesign.ca</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;