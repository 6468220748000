import { Marker, Popup } from "react-leaflet";
import { Link, useNavigate } from "react-router-dom";

import L from "leaflet";

const markerIcon = new L.Icon({
  iconUrl: require("../../Assets/images/location-pin.png"),
  iconSize: [75, 85],
});

function Travelled({position, link, city, country}) {

  const navigate = useNavigate();
  const handleClick = () => navigate(`${link}`);

    return (
      <body>
        <Marker
              position={position}
              icon={markerIcon}
              eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                click: (e) => {handleClick()},
              }}
            >
              <Popup>
                <Link to={link}>{city}, {country}</Link>
              </Popup>
            </Marker>
      </body>
    );
  }
  
  export default Travelled;