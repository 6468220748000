import { useState, useEffect } from "react";
import axios from "axios";
import xml2js from "xml2js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import "../../Assets/css/episodes.css";

function Episodes() {
  const PROXY_URL = process.env.REACT_APP_PROXY_URL;
  const URL = process.env.REACT_APP_FEED_URL;

  //  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [index, setIndex] = useState(6);

  const [xmlData, setXmlData] = useState([]);
  let parser = new xml2js.Parser();

  const fetchData = async () => {
    try {
      const response = await axios.get(PROXY_URL + URL);
      parser.parseString(response.data, function (err, result) {
        result = result.rss;
        result = result.channel;
        result = result[0];
        result = result.item;
        //testing

        //result = {
        //  title : result[0].title[0],
        //  description : result[0].description[0],
        //  image : result[0]['itunes:image'][0].$.href,
        //  audio : result[0].enclosure[0].$.url,
        //}

        const results = result.map((resul) => {
          return {
            title: resul.title[0],
            description: resul.description[0],
            image: resul["itunes:image"][0].$.href,
            audio: resul.enclosure[0].$.url,
          };
        });

        //---------AUDIO-----------------//
        //result = result[0];
        //result = result.enclosure;
        //result = result[0];
        //result = result.$;
        //result = result.url;

        //--------TITLE & DESCRIPTION----//
        //result = result[0];
        //result = result.title;
        //result = result[0];
        //-------------------------------//

        //---------IMAGES-------------//
        //result = result[0];
        //result = result['itunes:image'];
        //result = result[0];
        //result = result.$;
        //result = result.href;
        //----------------------------//

        if (results) {
          setXmlData(results);
          setFilteredData(results);
          return;
        }
        return;
      });
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const searchTerm = e.target.value;
    const filtered = xmlData.filter((data) => {
      return (
        data.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  const showMore = () => {
    if(filteredData.length-index <= 6)
    {
    setIndex(filteredData.length);
    }
    else{
    setIndex(index + 6);
    }
  };

  const showLess = () => {
    if(index === 1){
        return
    }
    else if(index<10) {
        setIndex(index - 1);
    }
    else {
    setIndex(index - 6);
    }
  };

  const slicedData = filteredData.slice(0, index);

  return (
    <body>
      <div className="episodes-intro-cont">
        <div className="num-episodes">
          <div>200+ Episodes</div>
        </div>
        <div className="episodes-par">
          <div>
            Join us weekly as we dive into the latest and greatest topical news
            stories and give our take on what is going on in this crazy world.
            We also sprinkle in some fun Am I The Asshole stories to switch
            things up from time to time. So whether you need a podcast for your
            daily walk, while you are cleaning, or if you just want some
            friendly company- tune in for new episodes weekly.{" "}
          </div>
        </div>
        <div className="saveg-rating">
          <div className="saveg-rating-inner1">
            <img
              className="spotify-rating"
              src="spotify.svg"
              alt="Spotify Logo"
            ></img>
            <div className="saveg-rating-number">5.0</div>
            <div className="saveg-rating-stars">
              <FontAwesomeIcon className="rating-star-icon" icon={faStar} />
              <FontAwesomeIcon className="rating-star-icon" icon={faStar} />
              <FontAwesomeIcon className="rating-star-icon" icon={faStar} />
              <FontAwesomeIcon className="rating-star-icon" icon={faStar} />
              <FontAwesomeIcon className="rating-star-icon" icon={faStar} />
            </div>
            <div className="saveg-rating-reviews">100+ Reviews</div>
          </div>
        </div>
      </div>
      <div className="episodes-cont">
        <input
          className="search-bar"
          type="search"
          placeholder="Search here"
          onChange={handleChange}
        />
        <div className="episode-card-cont">
        {slicedData.map((data) => {
          return (
            <div className="episode-card">
              <div className="episode-column">
                <img
                  className="episode-image"
                  src={data.image}
                  alt="Episode"
                ></img>
                <div className="episode-text">
                  <div className="episode-title">{data.title}</div>
                  <div className="episode-desc">{data.description}</div>
                </div>
              </div>
              <audio
                title={data.title}
                className="episode-audio"
                controls
                type="audio/mpeg"
                src={data.audio}
              ></audio>
            </div>
          );
        })}
        </div>
        <div className="show-cont">
        <div onClick={showMore} className="show-more">
          Show more
        </div>
        <div onClick={showLess} className="show-less">
          Show less
        </div>
        </div>
      </div>
    </body>
  );
}

export default Episodes;
