import First from "../../Assets/images/slideShow_image1.jpg";
import Second from "../../Assets/images/slideShow_image2.jpg";
import Third from "../../Assets/images/slideShow_image3.jpg";
import Fourth from "../../Assets/images/slideShow_image4.jpg";
import Fifth from "../../Assets/images/slideShow_image5.jpg";
import Sixth from "../../Assets/images/slideShow_image6.jpg";
import Seventh from "../../Assets/images/slideShow_image7.jpg";
import Eighth from "../../Assets/images/slideShow_image8.jpg";
import Ninth from "../../Assets/images/slideShow_image9.jpg";
import Tenth from "../../Assets/images/slideShow_image10.jpg";

export default [
{
    title: "Join us on Patreon!",
    urls: First,
},
{
    title: "Join us on Patreon!",
    urls: Second,
},
{
    title: "Join us on Patreon!",
    urls: Third,
},
{
    title: "Join us on Patreon!",
    urls: Fourth,
},
{
    title: "Join us on Patreon!",
    urls: Fifth,
},
{
    title: "Join us on Patreon!",
    urls: Sixth,
},
{
    title: "Join us on Patreon!",
    urls: Seventh,
},
{
    title: "Join us on Patreon!",
    urls: Eighth,
},
{
    title: "Join us on Patreon!",
    urls: Ninth,
},
{
    title: "Join us on Patreon!",
    urls: Tenth,
},
];