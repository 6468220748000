import React from "react";
import ReactPlayer from "react-player";

import WorldMap from "../auxiliary/WorldMap";

import { Link } from "react-router-dom";

import homepage_about from "../../Assets/images/homepage_about.jpg";

import "../../Assets/css/homepage.css";

function homepage({cities}) {
  return (
    <body>
      <div className="home-page-cont">
        <div className="vid-contcont">
          <div className="vid-cont">
            <ReactPlayer
              className="react-player fixed-bottom"
              url="homepage_clip.mp4"
              width="100%"
              height="100%"
              playing={true}
              loop={true}
              controls={false}
              muted={true}
              playsinline={true}
            />
          </div>
        </div>
        <Link to="/episodes" className="listen-now-link">
          <div className="listen-now">
            <div>Listen Now &#8607;</div>
          </div>
        </Link>
        <div className="intro-cont">
          <p className="intro-about">
            Join Youtuber and content creator Rose Lee (Cheap Lazy Vegan), and
            her friend Daniel as they offer their SaVeg take on a variety of
            topics from the latest news, travelling, eating, you name it!{" "}
            <br></br>
            <br></br>Sprinkling equal amounts of positivity and cynicism, link
            up with this dynamic duo and let’s spend some quality time together.
          </p>
          <div className="animation-cont">
            <div className="saveg-airplane-cont">
              <img
                className="saveg-airplane"
                src="airplane.svg"
                alt="airplane"
              ></img>
            </div>
            <div className="saveg-world-cont">
              <img className="saveg-world" src="world.svg" alt="world"></img>
            </div>
          </div>
        </div>
        <div className="about-hosts-title">About the Hosts</div>
        <div className="about-hosts-names">
          Rose Lee &<br></br> Daniel Davies
        </div>
        <img
          className="about-hosts-image"
          src={homepage_about}
          alt="Daniel and Rose"
        ></img>
        <div className="about-hosts-par">
          <p className="about-hosts-par1">
            We're Rose and Daniel, co-hosts of the SaVeg Podcast, entrepreneurs,
            creatives, best friends, travellers, and of course Vegans!
          </p>
          <p className="about-hosts-par2">
            {" "}
            We met in our first year of University at a fateful statistics class
            and have been friends ever since- who knew that statistics could
            bring people together? We both obtained business degrees from the
            University of Calgary and set out on our own professional journey's.
            Rose started working in corporate but soon transitioned to
            entrepreneur with her popular brand Cheap Lazy Vegan. Daniel
            continued his journey through corporate working in several different
            companies and more recently has started a property business. We've
            both travelled the world, doing many trips together as we explore
            everything this beautiful world has to offer. One thing that we both
            share is our love of chatting- hence how the SaVeg podcast was born.
          </p>
        </div>
        <div className="join-across">Join us Across the World</div>
        <div className="join-click">Try clicking on a location!</div>
        <div className="map-cont">
            <WorldMap id={0} cities={cities}/>
        </div>
      </div>
    </body>
  );
}

export default homepage;
