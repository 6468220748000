import { Route, Routes } from "react-router-dom";
import City from "./pages/City";

function CityCountry({wData}) {
  const cities = wData;
  return (
      <Routes>
        {cities.map((city, idx) => (
          <Route key = {idx} path={city.link} element={<City key={city.id} {...city} cities = {cities} />} />
        ))}
      </Routes>
  );
}

export default CityCountry;