import React, { useEffect, useState } from "react";
import * as contentful from "contentful";

import "./App.css";

// components
import Header from "./Components/header/header";
import Footer from "./Components/footer/footer";

import HomePage from "./Components/pages/homepage";
import Episodes from "./Components/pages/episodes";
import Membership from "./Components/pages/membership";
import Contact from "./Components/pages/contact";

import CityCountry from "./Components/CityCountry";

// routing
import { Route, Routes } from "react-router-dom";

function App() {

  const client = contentful.createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN
  });

  const [wData, wSetData] = useState([]);

  const getTravelledData = async () => {
    try {
      const response = await client.getEntries({content_type: 'cities'})
      var resData = response.items;

      //testing

      //resData = resData[0].fields.city;
      //resData = resData[0].fields.country;
      //resData = resData[0].fields.link;
      //resData = resData[0].fields.media[0].fields.file.url;
      //resData = resData[1].fields.recommendations;
      //resData = resData[0].fields.position;

      const results = resData.map((result) => {
        return result.fields.media ? {
          city: result.fields.city,
          country: result.fields.country,
          position: result.fields.position,
          link: result.fields.link,
          media: result.fields.media.map((img) => {
            return{
              url: img.fields.file.url
            }
          }),
          recommendations: result.fields.recommendations,
          savegtake: result.fields.saVegTake,
        } : 
        {
          city: result.fields.city,
          country: result.fields.country,
          position: result.fields.position,
          link: result.fields.link,
          media: [],
          recommendations: result.fields.recommendations,
          savegtake: result.fields.saVegTake
        }
      });

      const SortAsc = (arr) => {
        return arr.sort((a, b) => {
          if (a.country > b.country) { return 1; }
          if (b.country > a.country) { return -1; }
          return 0;
      })
    }

      resData = SortAsc(results);
      resData = resData.map((result, idx) => {
        return {
          id: idx,
          ...result
        }
      })

      const resultz = resData.map((result) => {
        return result.recommendations ? {
          ...result,
          recommendations: result.recommendations
        } : {
          ...result,
          recommendations: []
        }
      })

      wSetData(resultz);
      //console.log(resultz);
    } catch(error){
      console.log(error)
    }
  }

useEffect (() => {
  getTravelledData()
}, [])

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage cities={wData} />} />
        <Route path="/episodes" element={<Episodes/>}/>
        <Route path="/membership" element={<Membership/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
      <CityCountry wData={wData} />
      <Footer />
    </div>
  );
}

export default App;
