import React, { useEffect, useState, useRef } from "react";

import "../../Assets/css/header.css";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Header() {

  const [click, setClick] = useState(false);

  const closeMobileMenu = () => {
    setClick(false);
  };

  const handleClick = () => {
    setClick(!click);
  }

  const hideMenu = () => {
    if (window.innerWidth > 1000) {
      setClick(false);
    }
  };

  function listenForOutsideClicks(listening, setListening, menuRef, setIsOpen) {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
      [`click`, `touchstart`].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRef.current.contains(evt.target)) return;
          setIsOpen(false);
        });
      });
    }
  }

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);

  useEffect(listenForOutsideClicks(
    listening,
    setListening,
    menuRef,
    setClick,
  ));


  window.addEventListener("resize", hideMenu);

    return (
      <header>
        <div className="header-cont">
        <div className="home-nav"><Link to="/" className="nav-links">Home</Link></div>
        <div className="episode-nav"><Link to="/episodes" className="nav-links">Episodes</Link></div>
        <img className="saveg-logo" src="saveg_logo_bw.svg" alt="SaVeg Logo"></img>
        <div className="membership-nav"><Link to="/membership" className="nav-links">Membership</Link></div>
        <div className="contact-nav"><Link to="/contact" className="nav-links">Contact</Link></div>
        <FontAwesomeIcon ref={menuRef} className={click ? "hamburger-active" : "hamburger"} onClick={handleClick} icon={faBars} />
        <div className={click ? "hamburger-menu-cont-active" : "hamburger-menu-cont"}>
        <div className="home-nav2"><Link to="/" onClick={closeMobileMenu} className={click ? "nav-links2-active" : "nav-links2"}>Home</Link></div>
        <div className="episode-nav2"><Link to="/episodes" onClick={closeMobileMenu} className={click ? "nav-links2-active" : "nav-links2"}>Episodes</Link></div>
        <div className="membership-nav2"><Link to="/membership" onClick={closeMobileMenu} className={click ? "nav-links2-active" : "nav-links2"}>Membership</Link></div>
        <div className="contact-nav2"><Link to="/contact" onClick={closeMobileMenu} className={click ? "nav-links2-active" : "nav-links2"}>Contact</Link></div>
        </div>
        </div>
      </header>
    );
  }
  
  export default Header;