import React, { useState } from "react";

import "../../Assets/css/contact.css";

import contact_us from "../../Assets/images/contact_us.jpg";
import paper_airplane from "../../Assets/images/paper-airplane.png";

function Contact() {

  function toastPopup() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
  
    // Add the "show" class to DIV
    x.className = "show";
  
    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
  }

  //Email form
  const [emailForm, setEmailForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  //Result if message was sent or not
  const [result, setResult] = useState("");

  function resetEmailForm() {
    setEmailForm({ name: "", email: "", subject: "", message: "" });
  }

  function handleEmailFormChange(event) {
    setEmailForm((prevEmailData) => {
      return {
        ...prevEmailData,
        [event.target.name]: event.target.value,
      };
    });

    if (result.length > 0) {
      setResult("");
    }
  }

  const handleSubmit = async (e) => {
    setResult("");
    e.preventDefault();

    const { name, email, subject, message } = e.target.elements;

    let details = {
      name: name.value,
      email: email.value,
      subject: subject.value,
      message: message.value,
    };

    try {
      let response = await fetch(
        "https://saveg-podcast-c4bb84d67c1a.herokuapp.com/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(details),
        }
      );
      let result = await response.json();

      if (result.status === "success") {
        setResult("Message Sent!");
        resetEmailForm();
      } else if (result.status === "fail") {
        setResult("Message failed to send.");
      }
    } catch (error) {
      console.error(error);
      setResult("Issues with submitting message.");
    }
    toastPopup();
  };

  return (
    <div>
      <div id="snackbar">{result}</div>
      <img
        className="contact-us-image"
        src={contact_us}
        alt="Daniel and Rose"
      ></img>
      <div className="contact-form-cont">
        <form className="contact-form" onSubmit={handleSubmit} method='POST'>
          <input
            className="contact-form-name"
            placeholder="Name*"
            type="text"
            name="name"
            required={true}
            value={emailForm.name}
            onChange={handleEmailFormChange}
          />
          <input
            className="contact-form-email"
            placeholder="Email*"
            type="email"
            name="email"
            required={true}
            value={emailForm.email}
            onChange={handleEmailFormChange}
          />
          <input
            className="contact-form-subject"
            placeholder="Subject"
            type="subject"
            name="subject"
            required={false}
            value={emailForm.subject}
            onChange={handleEmailFormChange}
          />
          <textarea
            className="contact-form-text"
            maxLength={300}
            placeholder="Message (max 300 characters)*"
            name="message"
            required={true}
            value={emailForm.message}
            onChange={handleEmailFormChange}
          />
          <button className="submit-button" type="submit">
            <div className="submit-text-cont">
              <div className="submit-text">Submit</div>
              <img
                className="submit-airplane"
                src={paper_airplane}
                alt="Paper Airplane"
              ></img>
            </div>
          </button>
        </form>
        <div className="contact-form-email-desc">
          You can also email us directly at thesavegpodcast@gmail.com and we'll
          chat with you there!
        </div>
      </div>
      <div className="contact-form-bottom-spacer"></div>
    </div>
  );
}

export default Contact;
