import WorldMap from "../auxiliary/WorldMap";

import "../../Assets/css/city.css";

function City({ id, city, country, savegtake, media, cities }) {
  return (
    <body>
      <div className="city-cont">
        <div className="city-title">
          {" "}
          Join Rose and Daniel in {city}, {country}:{" "}
        </div>
        <div className="city-vegan">
          Our SaVeg Take:
          <div className="city-vegan-take">{savegtake}</div>
        </div>
        <div className="photo-grid">
          {media.map((image, idx) => (
            <img src={image.url} key={idx} alt={""} />
          ))}
        </div>
        <div className="map-cont">
          <WorldMap id={id} cities={cities} />
        </div>
      </div>
    </body>
  );
}

export default City;

/*
          <ul>
            {recommendations.map((item) => (
              <li> {item} </li>
            ))}
          </ul>
*/
