import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import Travelled from "./Travelled";

import { Link } from "react-router-dom";

const WorldMap = ({ id, cities }) => {
  return (
    <>
      <div className="travelled-locations-cont">
        {cities.map((travelled_location) => (
          <Link className="travelled-location-link" to={travelled_location.link}>
            <div key={travelled_location.id}>
              {" "}
              {travelled_location.city}, {travelled_location.country}{" "}
            </div>
          </Link>
        ))}
      </div>
      <div className="WorldMap-container">
        {cities.length > 1 ? (
          <MapContainer
            center={cities[id].position}
            zoom={4}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {cities.map((travelled_location) => (
              <Travelled key={travelled_location.id} {...travelled_location} />
            ))}
          </MapContainer>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default WorldMap;
